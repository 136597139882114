var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(" " + _vm._s(_vm.pageheading.toLocaleUpperCase()) + " ")]
          ),
        ]
      ),
      _vm.isLoading
        ? _c("Loading")
        : _c(
            "div",
            {
              staticStyle: { height: "100%", width: "100%!important" },
              attrs: { id: "body-content-area" },
            },
            [
              _c(
                "div",
                { staticClass: "page-wrapper", attrs: { id: "pagelayout" } },
                [
                  _c("div", { staticClass: "button-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-accent",
                        attrs: {
                          disabled:
                            (_vm.isNotNew && _vm.isSame) ||
                            !_vm.$hasPermissions(
                              _vm.clientSession,
                              ["PERMISSION_TEMPLATES"],
                              2
                            ),
                        },
                        on: { click: _vm.submit },
                      },
                      [_vm._v(" Save ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "button is-dark",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.back()
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                  ]),
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column column-is-one-half" }, [
                      _c(
                        "div",
                        {
                          staticClass: "block",
                          staticStyle: {
                            padding: "0px 0px 7px 0px",
                            "border-bottom": "solid 1px lightgray",
                          },
                        },
                        [
                          _c("span", { staticClass: "has-text-weight-bold" }, [
                            _vm._v("Permission Template"),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label is-size-7",
                            attrs: { for: "" },
                          },
                          [_vm._v("Name")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.input.name,
                              expression: "input.name",
                            },
                          ],
                          staticClass: "input",
                          attrs: { type: "text" },
                          domProps: { value: _vm.input.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.input, "name", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label is-size-7",
                            attrs: { for: "" },
                          },
                          [_vm._v("Description")]
                        ),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.input.description,
                              expression: "input.description",
                            },
                          ],
                          staticClass: "textarea",
                          attrs: { maxlength: "300", rows: "5", type: "text" },
                          domProps: { value: _vm.input.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.input,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            style: {
                              justifyContent: "flex-end",
                              width: "100%!important",
                              display: "flex",
                              opacity: _vm.inputOpacity,
                              color: _vm.$inputColorScale(
                                _vm.input.description
                                  ? _vm.input.description.length
                                  : 0,
                                [
                                  {
                                    color: "#7fb942",
                                    min: 0,
                                    max: 200,
                                  },
                                  {
                                    color: "#FFD300",
                                    min: 200,
                                    max: 225,
                                  },
                                  {
                                    color: "#ff9200",
                                    min: 225,
                                    max: 275,
                                  },
                                  {
                                    color: "#e82b18",
                                    min: 275,
                                    max: 301,
                                  },
                                ]
                              ),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.input.description
                                    ? _vm.input.description.length
                                    : 0
                                ) +
                                " "
                            ),
                            _c("div", { staticStyle: { color: "black" } }, [
                              _vm._v(" / 300"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "column column-is-one-half",
                        staticStyle: {
                          "padding-bottom": "5rem",
                          "max-height": "75vh",
                          overflow: "hidden",
                          "overflow-y": "scroll",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "block",
                            staticStyle: {
                              padding: "0px 0px 7px 0px",
                              "border-bottom": "solid 1px lightgray",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "has-text-weight-bold" },
                              [_vm._v("Default Permissions")]
                            ),
                          ]
                        ),
                        _vm.input.id !== undefined
                          ? _c(
                              "table",
                              {
                                staticClass: "table",
                                staticStyle: { width: "100%" },
                              },
                              [
                                _c("tr", [
                                  _c("th", { attrs: { colspan: "3" } }, [
                                    _vm._v("Section"),
                                  ]),
                                  _c(
                                    "th",
                                    { staticStyle: { width: "100px" } },
                                    [_vm._v("No-Access")]
                                  ),
                                  _c(
                                    "th",
                                    { staticStyle: { width: "100px" } },
                                    [_vm._v("Read-Only")]
                                  ),
                                  _c(
                                    "th",
                                    { staticStyle: { width: "100px" } },
                                    [_vm._v("Read/Write")]
                                  ),
                                ]),
                                _vm._l(_vm.permissions, function (permission) {
                                  return _c(
                                    "tr",
                                    {
                                      key: permission.code,
                                      on: {
                                        mouseup: function ($event) {
                                          _vm.isSame = false
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            color: "#363636!important",
                                          },
                                          attrs: { colspan: "3" },
                                        },
                                        [_vm._v(_vm._s(permission.name))]
                                      ),
                                      _c("td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.input.permissions[
                                                  permission.code
                                                ],
                                              expression:
                                                "input.permissions[permission.code]",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: permission.code,
                                          },
                                          domProps: {
                                            value: 0,
                                            checked: _vm._q(
                                              _vm.input.permissions[
                                                permission.code
                                              ],
                                              0
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.input.permissions,
                                                permission.code,
                                                0
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.input.permissions[
                                                  permission.code
                                                ],
                                              expression:
                                                "input.permissions[permission.code]",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: permission.code,
                                          },
                                          domProps: {
                                            value: 1,
                                            checked: _vm._q(
                                              _vm.input.permissions[
                                                permission.code
                                              ],
                                              1
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.input.permissions,
                                                permission.code,
                                                1
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.input.permissions[
                                                  permission.code
                                                ],
                                              expression:
                                                "input.permissions[permission.code]",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: permission.code,
                                          },
                                          domProps: {
                                            value: 2,
                                            checked: _vm._q(
                                              _vm.input.permissions[
                                                permission.code
                                              ],
                                              2
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.input.permissions,
                                                permission.code,
                                                2
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }